import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { SessionService } from '@monsido/core/session/session.service';
import { PagePerformanceService } from '@client/app/modules/page-performance/services/page-performance/page-performance.service';
import { cloneDeep } from 'lodash';


type FrequencyOptionType = {
    name: string,
    value: number,
}

@Injectable({
    providedIn: 'root',
})
export class FormPagePerformancePageService {
    readonly frequencyOptions = [
        {
            name: this.translateService.getString('Four times a day'),
            value: 6,
        },
        {
            name: this.translateService.getString('Twice a day'),
            value: 12,
        },
        {
            name: this.translateService.getString('Once a day'),
            value: 24,
        },
        {
            name: this.translateService.getString('Once a week'),
            value: 168,
        },
    ];

    constructor (
        private translateService: TranslateService,
        private sessionService: SessionService,
        private pagePerformanceService: PagePerformanceService,
    ) {}

    getFrequencyOptions (): FrequencyOptionType[] {
        return cloneDeep(this.getAvailableFrequencies());
    }

    getDefaultFrequencyOption (): number {
        let frequencies = this.getAvailableFrequencies();
        if (frequencies.length === 0) {
            frequencies = this.frequencyOptions;
        }
        return frequencies[frequencies.length - 1].value;
    }

    getMaxProfileCount (): number {
        return this.pagePerformanceService.getMaxProfileCount();
    }

    private getCustomerFrequencies (): number[] {
        return this.sessionService.customer?.plan_traits?.performance_tracking_allowed_frequencies || [];
    }

    private getAvailableFrequencies (): FrequencyOptionType[] {
        const frequencies = this.getCustomerFrequencies();
        if (!frequencies) {
            return this.frequencyOptions;
        }
        return this.frequencyOptions.filter((frequency) => {
            return frequencies.indexOf(frequency.value) > -1;
        });
    }
}
