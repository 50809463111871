import { DomainInterface } from '@monsido/modules/models/api/interfaces/domain.interface';
import moment from 'moment';
import { domainModelUtils } from './domain-model-utils/domain-model.utils';
import { User } from '@monsido/modules/models/api/user';

// eslint-disable-next-line @typescript-eslint/no-shadow
export enum DomainType { Domain = 'Domain', DomainGroup = 'DomainGroup'}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export interface Domain extends DomainInterface {
    users?: User[]
}
// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export class Domain {
    constructor (domain: DomainInterface) {
        Object.assign(this, domain);
        const offset = moment().utcOffset(); // Timezone in minutes
        domain = domain || {};

        this.active = !!domain.active;
        this.crawled_pages = domain.crawled_pages ?? 0;

        if (!domain.features) {
            this.features = {
                advanced_analytics: false,
                statistics: false,
                accessibility: null,
                readability_test: null,
                readability_test_min_words: 0,
                cookie_banner: false,
                page_assist: false,
                page_fix: false,
                data_protection: false,
                advanced_analytics_url: null,
            };
        }

        if (!domain.scan) {
            this.scan = {
                case_sensitive: true,
                connections_per_minute: 60,
                day: 'any',
                time: moment()
                    .set({ hour: 23, minute: 0, second: 0, millisecond: 0 })
                    .toISOString(),
                use_lang_attribute: true,
            };
        }

        if (this.scan.login === null || typeof this.scan.login !== 'object') {
            this.scan.login = {
                type: 'none',
            };
        }

        this.path_constraints = domain.path_constraints ?? [];
        this.link_excludes = domain.link_excludes ?? [];

        if (!domain.page_assist_settings) {
            this.page_assist_settings = {
                elements_to_hide: [],
                navigation: [],
                main: '',
                enabled: false,
                manual_startup: false,
                skip_to: null,
                theme: null,
                direction: null,
            };
        }

        if (this.id > 0) {
            const origTime = this.scan.time;
            const utcTime = moment(this.scan.time, 'HH:mm:ss');
            const selectedTime = moment(this.scan.time, 'HH:mm:ss').add(offset, 'minutes');
            this.scan.time = selectedTime.toISOString(true);
            // Converting day from UTC to current timezone for displaying in the view: Shift one day forward if there is day difference
            this.scan.day = domainModelUtils.getCurrentScanDay(
                this,
                origTime,
                this.scan.day,
                domainModelUtils.calculateDayDiffBetweenTimes(selectedTime, utcTime) * -1,
            );

            this.updated_at = moment(this.updated_at, 'YYYY-MM-DD[T]HH:mm:ss[Z]').toISOString();
            this.created_at = moment(this.created_at, 'YYYY-MM-DD[T]HH:mm:ss[Z]').toISOString();
        }

        if (domain.last_scan) {
            this.last_scan = moment(domain.last_scan, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').toISOString();
        }

        this.settings = domainModelUtils.prepareSettings(domain);
    }

}
