import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { PAGE_PERFORMANCE_AUDIT_INFO } from '../../constants/page-performance-audit-info';
import { ErrorHandler } from '@client/app/core/services/error-handler/error-handler';

@Injectable({
    providedIn: 'root',
})
export class PagePerformanceAuditInfoService {

    constructor (private translateService: TranslateService, private errorHandler: ErrorHandler) { }

    getDifficulty (id: string): string {
        const level = this.getDifficultyLevel(id);
        let difficulty = '';

        if (level !== null) {
            switch (level) {
                case 1:
                    difficulty = this.translateService.getString('Easy');
                    break;

                case 2:
                    difficulty = this.translateService.getString('Moderate');
                    break;

                case 3:
                    difficulty = this.translateService.getString('Hard');
                    break;
            }
        }

        return difficulty;
    }

    getDifficultyLevel (auditId: string): number | null {
        const audit = PAGE_PERFORMANCE_AUDIT_INFO.find((currentAudit) => currentAudit.id === auditId);
        if (audit) {
            return audit.difficulty;
        } else {
            // Log all Audits that were not found in the PAGE_PERFORMANCE_AUDIT_INFO constant
            this.errorHandler.noopError(
                'Performance audit id: ' + auditId + ' is missing in the Audit Info Service',
                'warning',
            );
        }
        return null;
    }
}
