import { default as PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG } from '@client/app/modules/page-fix/constants/page-fix-accessibility-check-config.json';
import { PAGE_FIX_FIXABLE_TYPES } from '@client/app/modules/page-fix/constants/page-fix-fixable-types';
import { PageFixModelHelper } from '@client/app/modules/page-fix/services/model-helper/page-fix-model-helper';
import { PageAccessibilityRepoCheckType } from '@client/app/services/api/page-accessibility/page-accessibility-repo.service';

// eslint-disable-next-line
export interface AccessibilityCheckModel extends PageAccessibilityRepoCheckType {
    html_snippet?: string;
    nameEscaped: string;
    issueNumberEscaped: number;

    getPageFixFixableId(sourceCodeId: number): number;
    getPageFixType (): string;
    getPageFixSelector (attrs: string[]): string;
}

// eslint-disable-next-line
export class AccessibilityCheckModel {

    constructor (data: PageAccessibilityRepoCheckType) {
        Object.assign(this, data);
        this.nameEscaped = this.name.replace(/<[^>]*>/g, '');
        if (typeof this.issue_number === 'string') {
            this.issueNumberEscaped = Number(this.issue_number.replace(/\./g, ''));
        } else if (typeof this.issue_number !== 'number') {
            this.issueNumberEscaped = 0;
        }
    }

    getPageFixFixableId (sourceCodeId: number): number {
        if (this.useCheckId(this.id)) {
            return this.id;
        }
        return sourceCodeId;
    }

    getPageFixType (): string {
        if (this.useCheckId(this.id)) {
            return PAGE_FIX_FIXABLE_TYPES.accessibility_check;
        }
        return PAGE_FIX_FIXABLE_TYPES.accessibility_source_code;
    }

    getPageFixSelector (attrs: string[] = []): string {
        const snippet = this.html_snippet || PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[this.id].snippet || '';
        return PageFixModelHelper.getSelectorValue(snippet, attrs);
    }

    private useCheckId (checkId: number): boolean {
        const checkConfig = PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[checkId];
        return Boolean(
            checkConfig && PAGE_FIX_FIXABLE_TYPES[checkConfig.type] === PAGE_FIX_FIXABLE_TYPES.accessibility_check,
        );
    }
}
