import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CrawlHistoryInfoService {

    private accessibilityErrorsOnly = new BehaviorSubject<boolean>(true);
    public readonly accessibilityErrorsOnly$ = this.accessibilityErrorsOnly.asObservable();

    constructor () { }

    setAccessibilityErrorsOnly (value: boolean): void {
        this.accessibilityErrorsOnly.next(value);
    }
}
