(function () {
    'use strict';

    angular.module('filters.monsido').filter('translateReadability', TranslateReadabilityController);

    TranslateReadabilityController.$inject = ['ng2QAReadabilityService'];
    /* @ngInject */
    function TranslateReadabilityController (ng2QAReadabilityService) {
        return result;

        // //////////

        function result (score, readabilityType) {
            if (!score) {
                return '';
            }

            return ng2QAReadabilityService.getTranslationScore(score, readabilityType);
        }
    }
})();
