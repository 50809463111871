(function () {
    'use strict';
    angular
        .module('pages.global.changesHistory')
        .service('ChangesHistoryReadabilityService', ChangesHistoryReadabilityService);

    ChangesHistoryReadabilityService.$inject = ['ng2QAReadabilityService'];
    /* @ngInject*/
    function ChangesHistoryReadabilityService (ng2QAReadabilityService) {
        var service = {
            getReadabilityLevels: getReadabilityLevels,
            getMostCommonReadabilityLevel: getMostCommonReadabilityLevel,
        };

        function init () {
            return service;
        }

        return init();

        function getReadabilityLevels (readabilityTest) {
            var readabilityLevels = ng2QAReadabilityService.getReadabilityLevels(readabilityTest);
            if (!readabilityLevels) {
                return [];
            }
            return readabilityLevels.map(function (level) {
                return getReadabilityLevelObject(level, readabilityTest);
            });
        }

        function getMostCommonReadabilityLevel (readabilityTest, readabilityDistribution) {
            var readabilityLevels = ng2QAReadabilityService.getReadabilityLevels(readabilityTest);
            if (!readabilityTest || !readabilityDistribution || !readabilityLevels || readabilityLevels.length === 0) {
                return null;
            }

            var maxPagesValue = 0;
            var mostCommonLevel = readabilityLevels[0];
            readabilityLevels.forEach(function (level) {
                if (readabilityDistribution[level] >= maxPagesValue) {
                    maxPagesValue = readabilityDistribution[level];
                    mostCommonLevel = level;
                }
            });

            return getReadabilityLevelObject(mostCommonLevel, readabilityTest);
        }

        // PROTECTED

        function getReadabilityLevelObject (level, readabilityTest) {
            var readabilityLevels = ng2QAReadabilityService.getReadabilityLevels(readabilityTest);
            return {
                attr: level,
                label: ng2QAReadabilityService.getTranslationScore(level, readabilityTest),
                color: ng2QAReadabilityService.getColorPallete()[readabilityLevels.indexOf(level)] || '#000000',
            };
        }
    }
})();
