export const VIRTUAL_URLS = {
    // FORMS
    formDomainGroupsEdit: { url: 'Domain groups for - edit' },
    formGlobalPolicy: { url: 'Global Policy Form' },
    'form-user': { url: 'User - edit' },
    uptime: { url: 'New Heartbeat Check' },
    formUser: { url: 'User - add' },
    formGlobalPolicyDialogRule: { url: 'Global Policy Edit Rule' },
    formScheduleReport: { url: 'Schedule Report Form' },
    formCustomerInfo: { url: 'Customer Info Form' },
    formUptimeScheduledDowntimes: { url: 'Heartbeat Scheduled Downtimes' },
    formShortcut: { url: 'Shortcuts Form' },
    formFeedback: { url: 'Feedback Form' },
    formDomain: { url: 'Domain Form' },
    formLabel: { url: 'Label Form' },
    formSourceCodeExclude: { url: 'Source Code Exclude Form' },
    formProfile: { url: 'Profile Form' },
    formDomainScriptGuide: { url: 'Domain Script Guide Form' },
    formApiUsers: { url: 'Api User Form' },
    formGlobalPolicyPreContent: { url: 'Global Policy Precontent Form' },
    formUptimeScheduledDowntime: { url: 'Heartbeat Scheduled Downtime Form' },
    formDomainGroupsCreate: { url: 'Domain Groups Form' },
    formDomainScriptGuideLegacy: { url: 'Domain Script Guide Legacy Form' },
    formDomainExcludeIp: { url: 'Domain Exclude Ip Form' },
    formPagePerformancePage: { url: 'Performance Page Form' },
    formPageAssist: { url: 'Page Assist Form' },
    formPagePerformanceProfile: { url: 'Page Performance Profile Form' },
    formImportGroupRules: { url: 'Import Group Rules Form' },
    formLiteDomain: { url: 'Lite Domain Form' },
    formLiteUser: { url: 'Lite User Form' },
    formPageAssistScriptSettings: { url: 'Page Assist Script Settings Form' },
    formConsentManagerSettings: { url: 'Consent Manager Script Settings Form' },

    // LISTS
    incidentList: { url: 'Uptime incident list' },
    readabilityPagesWithScore: { url: 'Readability Pages with Score for ' },
    linkContent: { url: 'Link Content' },
    // Other
    pageDetails: { url: 'Page Details for ' },
    paScriptSettingsComponent: { url: 'PageAssist Script setup guide' },
    consentManagerComponent: { url: 'Consent Manager Component' },
    documentDetails: { url: 'Document Details for ' },
    pagesPolicyDialogPages: { url: 'Content with Policy Matches for ' },
    pagesPolicyDialogLinksPages: { url: 'Policy Links Pages' },
    pagesPolicyDialogLinks: { url: 'Policy Links' },
    csvExportDialog: { url: 'CSV Exports Dialog ' },
    csvExportCompoundDialog: { url: 'CSV Export Compound Dialog' },
    accessibilityFastTrack: { url: 'Accessibility Fast Track ' },
    accessibilityIssuesPages: { url: 'Accessibility Issues' },
    guidelinesDialogsChecks: { url: 'Accessibility Guidelines Dialog ' },
    seoDialogPagesWithDuplicateTitle: { url: 'SEO Pages with Duplicate Titles for ' },
    pagesFromSeoIssues: { url: 'Pages From SEO Issues ' },
    pagesFilePages: { url: 'Pages with Files ' },
    pagesEmail: { url: 'Pages with Email ' },
    inventoryDialogsContentLinks: { url: 'Pages with Content Links Dialog ' },
    extensionGuideDialog: { url: 'Extension Guide Dialog ' },
    spellingPagesDialog: { url: 'Spelling Page Dialog' },
    pageDetailsSectionAccessibilityInformationFastTrack: {
        url: 'Page Details Accessibility Fast Track',
    },
    pageDetailsSeoPagesDialog: { url: 'Page Details SEO Pages Dialog' },
    scheduledReportsList: { url: 'Scheduled Reports List' },
    csvExportsOverlay: { url: 'My Exports' },
    reportCenterSendMeReport: { url: 'Report Center - Send Me Report' },
    globalPolicyDialogsTargets: { url: 'Global Policy Targets Dialog' },
    pagesFilesPages: { url: 'Page Details Files' },
    fastTrackDialogsPages: { url: 'Fast Track Pages' },
    accessibilityDialogsHelpCenter: { url: 'Accessibility Help Center' },
    scanDetailsDialog: { url: 'Scan Details' },
    reportCenterReceivedReportsDialog: { url: 'Report Center Received Reports' },
    helpDialog: { url: 'Help Dialog' },
    pagesFiles: { url: 'Files' },
    pagePerformanceDialogsAuditDetails: { url: 'Page Performance Audit Details Dialog' },
    adminAuditlogChanges: { url: 'Admin Auditlog Changes' },
    accessibilityDialogsNewHelpCenter: { url: 'New Accessibility Help Center' },
    accessibilityDialogsNewHelpCenterDecisionTree: {
        url: 'Accessibility Help Center Decision Tree',
    },
    csvExportAuditLogDialog: { url: 'CSV Export Audit Log Dialog' },
    allNotificationsDialog: { url: 'All Notifications Dialog' },
    dataPrivacyDialogsHelpCenter: { url: 'Data Privacy Dialogs Help Center' },
    datePrivacyInfoTypePages: { url: 'Date Privacy Info Type Pages' },
    privacyViolationPages: { url: 'Privacy Violation Pages' },
    privacyDialogsHelpCenter: { url: 'Privacy Dialogs Help Center' },
    privacyDialogsFastTrack: { url: 'Privacy Dialogs Fast Track' },
    pageFixFormDomainSettings: { url: 'PageCorrect Domain Settings' },
    domainDataPrivacySettings: { url: 'Data Privacy Domain Settings' },
    adminAuditlogMoreInfo: { url: 'Admin Audit log More Info' },
    labelBlockedByDomainGroups: { url: 'Label Removal Blocked By Domain Groups Dialog' },
} as const;
