import { LineChart } from '@client/app/modules/charts/models/charts/line';
import MONSIDO_COLOR from '@monsido/colors';

(function () {
    'use strict';

    angular.module('modules.page-performance.page-details').component('pagePerformancePageDetailsHistory', {
        templateUrl: 'app/modules/page-performance/page-details/history/history.html',
        controller: PagePerformancePageDetailsHistoryController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            profile: '<',
        },
    });

    PagePerformancePageDetailsHistoryController.$inject = [
        'moment',
        'gettextCatalog',
        '$filter',
        'ng2PagePerformanceResultsRepoService',
        'ng2HistoryHelperService',
        'PagePerformanceVersionService',
        'DATE_FORMAT',
    ];

    /* @ngInject */
    function PagePerformancePageDetailsHistoryController (
        moment,
        gettextCatalog,
        $filter,
        ng2PagePerformanceResultsRepoService,
        ng2HistoryHelperService,
        PagePerformanceVersionService,
        DATE_FORMAT,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.onDateRangeChanges = onDateRangeChanges;
        vm.getChangeValue = getChangeValue;
        vm.getChangePercentageValue = getChangePercentageValue;
        vm.changesColorClass = changesColorClass;
        vm.changesIconClass = changesIconClass;
        vm.formatFieldValue = formatFieldValue;

        function activate () {
            vm.date = {
                startDate: moment().subtract(1, 'months'),
                endDate: moment(),
            };
            vm.dateFormat = DATE_FORMAT;
            vm.history = [];
            vm.versions = [];
            vm.seriesByVersion = {};
            vm.metrics = [];
            vm.metricsIds = {};
            vm.datePickerOptions = {
                maxDate: moment().toDate(),
            };
            setupChart();
            onDateRangeChanges();
        }

        function getPerformanceResults () {
            var params = {
                page_id: vm.page.id,
                profile_id: vm.profile.id,
                from: vm.date.startDate.format('YYYY-MM-DD'),
                to: vm.date.endDate.format('YYYY-MM-DD'),
            };
            vm.progress = ng2PagePerformanceResultsRepoService.getAll(params).then(function (res) {
                vm.history = res.reverse().map(function (entry, index) {
                    entry.id = index;
                    return entry;
                });
                var seriesByVersion = PagePerformanceVersionService.getSeriesByVersion(vm.history, 'version');
                vm.versions = seriesByVersion.versions;
                vm.seriesByVersion = seriesByVersion.series;

                if (vm.versions.length === 1) {
                    vm.metrics = vm.versions[0].metrics;
                } else {
                    vm.metrics = [];
                    vm.metricsIds = {};
                    for (var i = 0; i < vm.versions.length; i++) {
                        var versionMetrics = vm.versions[i].metrics;
                        for (var j = 0; j < versionMetrics.length; j++) {
                            var versionMetric = versionMetrics[j];
                            if (!vm.metricsIds[versionMetric.id]) {
                                vm.metrics.push(versionMetric);
                                vm.metricsIds[versionMetric.id] = versionMetric;
                            }
                        }
                    }
                }
            }, angular.noop);
            return vm.progress;
        }

        function onDateRangeChanges () {
            getPerformanceResults().then(function () {
                setupChart();
                updateChart();
            });
        }

        function setupChart () {
            vm.lineChartSettings = new LineChart();
            vm.lineChartData = [];
            vm.lineChartLabels = [];
            var availableColors = [
                '#f4151e',
                '#6122ee',
                MONSIDO_COLOR['secondary-12'],
                '#00DEDB',
                '#FF9879',
                MONSIDO_COLOR['secondary-15'],
                MONSIDO_COLOR['secondary-11'],
                MONSIDO_COLOR['secondary-16'],
            ];

            var availableOverrides = [
                {
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-0',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    radius: 0,
                    borderWidth: 1,
                    pointRadius: 3,
                    pointHitRadius: 34,
                },
                {
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-1',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    borderDash: [6, 6],
                },
                {
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-1',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                },
                {
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-1',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    borderDash: [4, 4],
                },
                {
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-1',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                },
                {
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-1',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    borderDash: [2, 2],
                },
                {
                    xAxisID: 'x-axis-0',
                    yAxisID: 'y-axis-2',
                    type: 'line',
                    fill: false,
                    lineTension: 0,
                    pointRadius: 0,
                },
            ];

            var colors = [];
            var series = [gettextCatalog.getString('Performance')];
            var overrides = [availableOverrides[0]];

            for (var k = 0; k < vm.metrics.length; k++) {
                var metric = vm.metrics[k];
                colors.push(availableColors[k]);
                series.push(metric.chartLabel);

                var override = availableOverrides[k + 1];
                if (!override) {
                    override = {
                        xAxisID: 'x-axis-0',
                        type: 'line',
                        fill: false,
                        lineTension: 0,
                        pointRadius: 0,
                        pointHitRadius: 10,
                        borderDash: [2, 2],
                    };
                }
                override.yAxisID = getYAxis(metric.unit);
                overrides.push(override);
            }

            vm.lineChartSettings.addSeries(series);
            vm.lineChartSettings.addColors(colors);
            vm.lineChartSettings.addOverrides(overrides);

            vm.lineChartSettings.setTooltipSettings({
                intersect: false,
                mode: 'x-axis',
                callbacks: {
                    label: function (tooltipItem, data) {
                        var item = data.datasets[tooltipItem.datasetIndex];
                        var result = item.data[tooltipItem.index].y;
                        var label = item.label;
                        var unit =
                            [gettextCatalog.getString('Performance'), gettextCatalog.getString('Cumulative Layout Shift')].indexOf(label) <
                            0
                                ? 'ms'
                                : '';
                        /* jshint ignore:start */
                        var decimals =
                            label === gettextCatalog.getString('Cumulative Layout Shift')
                                ? $filter('monNumber')(result, '0.[00]')
                                : $filter('monNumber', '0,0')(result);
                        /* jshint ignore:end */
                        return item.label + ': ' + decimals + unit;
                    },
                    title: function (tooltipItem) {
                        return moment(tooltipItem[0].xLabel).format('LL LTS');
                    },
                },
            });

            vm.lineChartSettings.setLegendSettingsOverride({
                display: true,
                position: 'bottom',
                labels: {
                    fontSize: 11,
                    boxWidth: 10,
                },
            });

            vm.lineChartSettings.setScalesSettings({
                yAxes: [
                    {
                        id: 'y-axis-0',
                        type: 'linear',
                        position: 'left',
                        ticks: {
                            fontSize: 11,
                            min: 0,
                            max: 100,
                            beginAtZero: true,
                            suggestedMax: 5,
                            maxTicksLimit: 5,
                            callback: function (value) {
                                return $filter('monNumber')(value, '0,0');
                            },
                        },
                        gridLines: {
                            drawBorder: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: gettextCatalog.getString('Score'),
                            fontSize: 11,
                        },
                    },
                    {
                        id: 'y-axis-1',
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            fontSize: 11,
                            min: 0,
                            beginAtZero: true,
                            suggestedMax: 5,
                            maxTicksLimit: 5,
                            callback: function (value) {
                                return $filter('monNumber')(value, '0,0');
                            },
                        },
                        gridLines: {
                            drawBorder: false,
                            display: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: gettextCatalog.getString('Milliseconds'),
                            fontSize: 11,
                        },
                    },
                    {
                        id: 'y-axis-2',
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            fontSize: 11,
                            min: 0,
                            max: 1,
                            beginAtZero: true,
                            suggestedMax: 1,
                            maxTicksLimit: 2,
                            callback: function (value) {
                                return $filter('monNumber')(value, '0,00');
                            },
                        },
                        gridLines: {
                            drawBorder: false,
                            display: false,
                        },
                    },
                ],
                xAxes: [
                    {
                        id: 'x-axis-0',
                        type: 'time',
                        gridLines: {
                            offsetGridLines: true,
                            drawBorder: false,
                            display: false,
                        },
                        ticks: {
                            fontSize: 11,
                            maxTicksLimit: 30,
                        },
                        time: {
                            minUnit: 'day',
                            displayFormats: {
                                millisecond: 'MMM D',
                                second: 'MMM D',
                                minute: 'MMM D',
                                hour: 'MMM D',
                                day: 'MMM D',
                                week: 'MMM D',
                                month: 'MMM D',
                                quarter: 'MMM D',
                                year: 'MMM D',
                            },
                        },
                    },
                ],
            });
        }

        function getYAxis (unit) {
            switch (unit) {
                case 'ms':
                    return 'y-axis-1';
                case '':
                    return 'y-axis-2';
                default:
                    return 'y-axis-0';
            }
        }

        function updateChart () {
            vm.lineChartData = [[]];
            vm.metrics.forEach(function () {
                vm.lineChartData.push([]);
            });
            vm.history.forEach(function (entry) {
                vm.lineChartData[0].push(transformChartData(entry, 'performance'));
                vm.metrics.forEach(function (metric, index) {
                    vm.lineChartData[index + 1].push(transformChartData(entry, metric.id));
                });

                vm.lineChartLabels.push(new Date(entry.scanned_at).getTime());
            });

            var entriesWithVersionChanged = PagePerformanceVersionService.getEntriesWithVersionChanged(vm.history, 'version');
            if (entriesWithVersionChanged.length > 0) {
                vm.lineChartSettings.options.verticalLines = {
                    lines: entriesWithVersionChanged.map(function (entry) {
                        var versionInfo = PagePerformanceVersionService.getVersionInfo(entry.version);
                        return {
                            xAxisID: 'x-axis-0',
                            x: entry.scanned_at,
                            style: {
                                lineDash: [5, 5],
                                color: MONSIDO_COLOR['secondary-15'],
                                width: 3,
                            },
                            label: {
                                text: versionInfo.label,
                                color: MONSIDO_COLOR['secondary-15'],
                                position: 'right',
                            },
                            data: {
                                link: versionInfo.link,
                            },
                        };
                    }),
                };
            }
        }

        function transformChartData (entry, field) {
            var scoreFields = ['performance'];
            if (scoreFields.indexOf(field) !== -1) {
                return {
                    x: moment(entry.scanned_at),
                    y: entry[field] * 100,
                };
            } else {
                return {
                    x: moment(entry.scanned_at),
                    y: entry[field],
                };
            }
        }

        function changesColorClass (story, field) {
            const value = vm.getChangeValue(story.id, field);
            return ng2HistoryHelperService.getChangeColorClass(value);
        }

        function changesIconClass (story, field) {
            const value = vm.getChangeValue(story.id, field) * -1;
            return ng2HistoryHelperService.getChangeIconClass(value);
        }

        function getChangeValue (id, field) {
            return ng2HistoryHelperService.getChangeValue(id, vm.history, [field]);
        }

        function getChangePercentageValue (id, field) {
            return ng2HistoryHelperService.getChangePercentageValue(id, vm.history, [field]);
        }

        function formatFieldValue (value, field) {
            if (!value) {
                return 0;
            }
            if (field === 'cumulative_layout_shift') {
                return $filter('monNumber')(value, '0.[00]');
            }
            return $filter('monNumber')(value, '0,0]') + ' ms';
        }
    }
})();
