(function () {
    'use strict';

    angular.module('modules.export').factory('exporterReadabilityParser', exporterReadabilityParser);

    exporterReadabilityParser.$inject = ['$filter', 'gettextCatalog', 'ng2QAReadabilityService'];
    /* @ngInject */
    function exporterReadabilityParser ($filter, gettextCatalog, ng2QAReadabilityService) {
        var service = {
            parse: parse,
        };

        return service;

        // ///////////////

        function getHeadlines (readabilityLevels, readabilityTest) {
            var headlines = [gettextCatalog.getString('Crawled at'), gettextCatalog.getString('Pages Crawled')];

            readabilityLevels.forEach(function (level) {
                headlines.push(ng2QAReadabilityService.getTranslationScore(level, readabilityTest));
            });

            return headlines;
        }

        function parse (data) {
            var csv = [];
            var entry = [];
            if (!data || data.length === 0) {
                return csv;
            }
            var readabilityTest = data[0].readability_test;
            var readabilityLevels = ng2QAReadabilityService.getReadabilityLevels(readabilityTest);
            csv.push(getHeadlines(readabilityLevels, readabilityTest));

            for (var i = 0; i < data.length; i++) {
                entry = [];
                entry.push($filter('userDate')(data[i].post_processing_done_at || data[i].date, 'LL LT'));
                entry.push(data[i].page_count + data[i].documents_count);
                for (var j = 0; j < readabilityLevels.length; j++) {
                    entry.push(data[i].readability_distribution[readabilityLevels[j]]);
                }
                csv.push(entry);
            }
            return csv;
        }
    }
})();
