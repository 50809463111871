(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionQa', {
        templateUrl: 'app/components/pagedetails/details/sections/qa/qa.html',
        controller: SectionQpController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            active: '<',
            refreshPage: '&',
        },
    });

    SectionQpController.$inject = ['ng2SessionService', 'ng2ActiveFeatureService'];
    /* @ngInject */
    function SectionQpController (ng2SessionService, ng2ActiveFeatureService) {
        var vm = this;
        vm.$onInit = activate;
        vm.onRefreshPage = onRefreshPage;

        function activate () {
            vm.showBrokenLinks = ng2ActiveFeatureService.isFeatureActivePure('qa_links');
            vm.showSpell = ng2ActiveFeatureService.isFeatureActivePure('qa_spellcheck');
            vm.showReadability = ng2ActiveFeatureService.isFeatureActivePure('admin_readability');
            vm.active = selectQaSection();
            vm.page = vm.page || {};
        }

        function selectQaSection () {
            var qaSections = [];
            if (vm.showBrokenLinks) {
                qaSections.push('brokenlinks');
                qaSections.push('brokenimages');
            }
            if (vm.showSpell) {
                qaSections.push('misspellings');
                qaSections.push('potential');
                qaSections.push('spell-ignored');
            }
            if (vm.showReadability) {
                qaSections.push('readability');
            }

            var index = qaSections.indexOf(vm.active);
            if (index === -1) {
                return qaSections[0];
            }
            return qaSections[index];
        }

        function onRefreshPage () {
            vm.refreshPage({ target: 'page-details-section-qa' });
        }
    }
})();
