import { downgradeComponent } from '@angular/upgrade/static';
import { MonVerticalScoreComponent } from '../modules/score/components/mon-vertical-score/mon-vertical-score.component';
import {
    MonLegacyBrowserNotificationComponent,
} from '@monsido/modules/legacy-browsers/legacy-browser-notification.component';
import {
    PageHeaderComponent,
    IconComponent,
    StackedIconComponent,
    MonDatePickerComponent,
    LabelTableComponent,
    MonSwitchComponent,
    DynamicAcComponent,
    UrlTitleComponent,
    MonTableSortComponent,
    TableSearchComponent,
    TabsComponent,
    SwitchButtonComponent,
    SelectComponent,
    SelectNextAcComponent,
} from '@monsido/angular-shared-components/dist/angular-shared-components';
import {
    MonCookieOverviewLanguageTabs,
} from '@monsido/modules/cookie-banner/components/cookie-overview-language-tabs.component';
import {
    MonScrollableDropdownMenuComponent,
} from '@layout/scrollable-dropdown-menu/scrollable-dropdown-menu.component';
import {
    PAScriptSettingsComponent,
} from 'app/forms/domain-script-guide/features/pa-script-settings/pa-script-settings.component';
import { DialogContainerComponent } from '@monsido/core/modules/dialog-container/dialog-container.component';
import {
    MonDataPrivacyInfoTypeIconComponent,
} from '@monsido/modules/data-privacy/components/info-type-icon/info-type-icon.component';
import {
    ConsentManagerComponent,
} from '../forms/domain-script-guide/features/consent-manager/consent-manager.component';
import {
    MonLabelBlockedByDomainGroupsComponent,
} from '@monsido/modules/labels/modals/label-blocked-by-domain-groups.component';
import {
    FormDomainGroupsMatchRuleLabelComponent,
} from '@monsido/forms/domain/steps/domain-groups/steps/rules/match-rule/templates/label/label.component';
import {
    MonDataPrivacySeverityLevelComponent,
} from '@monsido/modules/data-privacy/components/severity-level/severity-level.component';
import {
    OverlayWrapperComponent,
} from 'app/modules/overlay-wrapper/components/overlay-wrapper/overlay-wrapper.component';
import {
    ShowMoreAvatarsComponent,
} from '@monsido/modules/report-center/components/show-more-avatars/show-more-avatars.component';
import {
    SimpleDoughnutChartComponent,
} from '../../ng2/shared/components/simple-doughnut-chart/simple-doughnut-chart.component';
import { LinkComponent } from 'ng2/shared/components/link/link.component';
import { MonButtonNextComponent } from '@monsido/ng2/shared/components/button/button-next.component';
import { MonActionMenuComponent } from 'ng2/shared/components/action-menu/action-menu.component';
import { MonActionMenuPanelComponent } from 'ng2/shared/components/action-menu-panel/action-menu-panel.component';
import { FaviconWrapperComponent } from 'ng2/layout/favicon-wrapper/favicon-wrapper.component';
import { TablePolicyTypeComponent } from '@monsido/modules/table-policy-type/table-policy-type.component';
import { MonDataPrivacyDropdownComponent } from '@monsido/modules/data-privacy/components/dropdown/dropdown.component';
import { MarkdownViewerComponent } from '../components/markdown-viewer/markdown-viewer.component';
import { PageDetailsSectionAccessibilityImageComponent } from '@monsido/ng2/modules/page-details/components/page-details-section-accessibility-image/page-details-section-accessibility-image.component';
import { DomainPickerComponent } from '../forms/global-policy/steps/rules/boxes/settings/domain-picker/domain-picker.component';

function allowAttribute (componentFactory) {
    const wrapper = function ($compile, $injector, $parse) {
        const component = componentFactory($compile, $injector, $parse);
        component.restrict = 'AE';
        return component;
    };
    wrapper.$inject = ['$compile', '$injector', '$parse'];
    return wrapper;
}

(function () {
    'use strict';
    angular
        .module('app.components')
        .directive('monTableSort', allowAttribute(downgradeComponent({ component: MonTableSortComponent })))
        .directive('monTableSearch', allowAttribute(downgradeComponent({ component: TableSearchComponent })))
        .directive('monLink', downgradeComponent({ component: LinkComponent }))
        .directive('monActionMenu', downgradeComponent({ component: MonActionMenuComponent }))
        .directive('monActionMenuPanel', downgradeComponent({ component: MonActionMenuPanelComponent }))
        .directive('monButtonNext', downgradeComponent({ component: MonButtonNextComponent }))
        .directive('monUrlTitle', downgradeComponent({ component: UrlTitleComponent }))
        .directive('monFormFieldDynamic',
            downgradeComponent({
                component: DynamicAcComponent,
                inputs: [
                    'allowSearch',
                    'allowPagination',
                    'pageSize',
                    'label',
                    'type',
                    'placeholder',
                    'confirmMsg',
                    'attrName',
                    'helpText',
                    'textInAddBtn',
                ],
                outputs: [
                    'inputValueChanged',
                ],
            }),
        )
        .directive('monSwitch',
            downgradeComponent({ component: MonSwitchComponent, inputs: ['color', 'monLabel', 'name'] }),
        )
        .directive(
            'monShowMoreAvatars',
            downgradeComponent({ component: ShowMoreAvatarsComponent, inputs: ['users', 'minElements'] }))
        .directive(
            'monDialogContainer',
            downgradeComponent({ component: DialogContainerComponent }),
        )
        .directive(
            'monOverlayWrapper',
            downgradeComponent({ component: OverlayWrapperComponent }),
        )
        .directive(
            'monPageHeader',
            downgradeComponent({
                component: PageHeaderComponent,
                inputs: [
                    'headerType',
                    'monIcon',
                    'panelClass',
                    'header',
                    'subHeader',
                    'panelLeftClass',
                    'panelRightClass',
                    'trustAsHtml',
                    'subHeaderTooltipPlacement',
                    'subHeaderTooltipEnable',
                ],
            }),
        )
        .directive(
            'monIcon',
            downgradeComponent({
                component: IconComponent,
                inputs: [
                    'size',
                    'styles',
                    'icon',
                    'iconProp',
                    'stackItemSize',
                    'monIconDirect',
                    'spin',
                ],
            }),
        )
        .directive(
            'monStackedIcon',
            downgradeComponent({
                component: StackedIconComponent,
                inputs: ['iconFront', 'iconColor', 'iconSize', 'spin'],
            }),
        )
        .directive(
            'monVerticalScore',
            downgradeComponent({
                component: MonVerticalScoreComponent,
                inputs: ['monSize', 'monChartValue', 'monTitle', 'monTooltipText', 'monLabels'],
            }),
        )
        .directive('paScriptSettingsComponent', downgradeComponent({ component: PAScriptSettingsComponent }))
        .directive('consentManagerComponent', downgradeComponent({ component: ConsentManagerComponent }))
        .directive(
            'monLegacyBrowserNotification',
            downgradeComponent({
                component: MonLegacyBrowserNotificationComponent,
                inputs: ['monMinimized'],
                outputs: ['monClose'],
            }),
        )
        .directive(
            'monScrollableDropdownMenu',
            downgradeComponent({
                component: MonScrollableDropdownMenuComponent,
                inputs: ['monMenuItems', 'monLabelledBy', 'monId', 'monFocusFirstMenuItem'],
                outputs: ['monOnSelect', 'monOnClose'],
            }),
        )
        .directive(
            'monSwitchButton',
            downgradeComponent({
                component: SwitchButtonComponent,
                inputs: ['options', 'label', 'defaultIndex'],
                outputs: ['onSelected'],
            }),
        )
        .directive(
            'monFormFieldSelect',
            downgradeComponent({
                component: SelectComponent,
                inputs: [
                    'model',
                    'monAllowClear',
                    'monPlaceholder',
                    'monOptions',
                    'monLabel',
                    'monHelpText',
                    'monDefaultOptions',
                    'monReturnValue',
                    'monRequired',
                    'monMultiple',
                    'monGroup',
                    'monNameAttr',
                    'monViewTemplate',
                    'monDropdownTemplate',
                    'monGroupTemplate',
                    'monRemoveButton',
                    'monDisabled',
                    'monDisableSearch',
                    'monName',
                    'monNoAutoComplete',
                    'inputWidth',
                    'monShowColors',
                ],
                outputs: ['changeEvent'],
            }),
        )
        .directive(
            'monFormFieldSelectNextAc',
            downgradeComponent({
                component: SelectNextAcComponent,
                inputs: [
                    'options',
                    'placeholder',
                    'label',
                    'hideLabel',
                    'size',
                    'allowClear',
                    'describedBy',
                    'monId',
                ],
            }),
        )
        .directive(
            'monAscDatePicker',
            downgradeComponent({
                component: MonDatePickerComponent,
                inputs: [
                    'date',
                    'options',
                    'unRestrictedMax',
                    'monEnableComparisonRange',
                    'monExpandComparison',
                    'monDateFormat',
                    'monCompareDates',
                    'monValidateStartDateCb',
                    'monValidateEndDateCb',
                ],
                outputs: ['dateChange', 'comparisonUpdate'],
            }),
        )
        .directive(
            'monLabelTable',
            downgradeComponent({
                component: LabelTableComponent,
                inputs: [
                    'existingLabels',
                    'assignedLabels',
                    'noLabelSelector',
                ],
                outputs: ['onUpdateLabel', 'getLabels', 'createOrUpdateLabel'],
            }),
        )
        .directive(
            'monSimpleDoughnutChart',
            downgradeComponent({
                component: SimpleDoughnutChartComponent,
                inputs: [
                    'percentage',
                ],
            }),
        )
        .directive('monFaviconWrapperComponent',
            downgradeComponent({
                component: FaviconWrapperComponent,
                inputs: [
                    'monSrc',
                    'monAlt',
                ],
            }),
        )
        .directive('monTablePolicyType',
            downgradeComponent({
                component: TablePolicyTypeComponent,
                inputs: [
                    'target',
                    'tooltip',
                    'passed',
                    'isMobileCheck',
                    'color',
                ],
            }))
        .directive('monMarkdownViewer',
            downgradeComponent({
                component: MarkdownViewerComponent,
            }),
        )
        .directive('monAscTabs',
            downgradeComponent({
                component: TabsComponent,
                inputs: [
                    'status',
                    'showSearch',
                    'showFiltersBtn',
                    'isFiltersOpen',
                    'search',
                    'options',
                    'maxOptionsDisplay',
                    'minSearchLength',
                ],
                outputs: ['onStatus', 'onSearch'],
            }),
        )
        .directive('monDomainPicker',
            downgradeComponent({
                component: DomainPickerComponent,
            }),
        )
    ;

    angular.module('modules.cookie_banner.components').directive(
        'monCookieOverviewLanguageTabs',
        downgradeComponent({
            component: MonCookieOverviewLanguageTabs,
            inputs: ['monLanguages', 'monShowLanguages', 'monModel'],
            outputs: [
                'monModelChange',
                'monOnAddLanguage',
                'monOnRemoveLanguage',
            ],
        }),
    );

    angular.module('modules.data_privacy.components')
        .directive(
            'monDataPrivacyInfoTypeIcon',
            downgradeComponent({
                component: MonDataPrivacyInfoTypeIconComponent,
                inputs: ['monPriority', 'monGroup', 'monTooltipText'],
            }),
        )
        .directive('monDataPrivacySeverityLevel',
            downgradeComponent({
                component: MonDataPrivacySeverityLevelComponent,
                inputs: ['monPriority'],
            }),
        )
        .directive('monDataPrivacyDropdown',
            downgradeComponent({
                component: MonDataPrivacyDropdownComponent,
                inputs: ['monViolation', 'monActiveTab', 'monUpdateCallback'],
            }),
        );

    angular.module('pages.admin.labels').directive(
        'monLabelBlockedByDomainGroups',
        downgradeComponent({
            component: MonLabelBlockedByDomainGroupsComponent,
            inputs: ['monLabel', 'monDomains'],
        }),
    );

    angular.module('app.components.pageDetails')
        .directive(
            'monPageDetailsSectionAccessibilityImage',
            downgradeComponent({
                component: PageDetailsSectionAccessibilityImageComponent,
                inputs: ['page', 'model'],
            }),
        );

    angular.module('app.forms')
        .directive('monFormDomainGroupsMatchRuleLabel',
            downgradeComponent({
                component: FormDomainGroupsMatchRuleLabelComponent,
                inputs: [
                    'monRule',
                    'monMatchOptions',
                    'monMatchExcludeOptions',
                    'monLabelsOptions',
                    'monFieldKey',
                ],
                outputs: [
                    'monExcludeChange',
                ],
            }),
        );
})();
