(function () {
    'use strict';

    angular.module('pages.domain').component('pageDetailsDashboardChartsQa', {
        templateUrl: 'app/components/pagedetails/details/sections/dashboard/charts/qa/qa.html',
        controller: DashboardController,
        controllerAs: 'vm',
        bindings: {
            page: '<',
            onClickHandler: '&',
        },
    });

    DashboardController.$inject = ['gettextCatalog', 'ng2SessionService', 'ng2ActiveFeatureService'];
    /* @ngInject */
    function DashboardController (gettextCatalog, ng2SessionService, ng2ActiveFeatureService) {
        var vm = this;
        vm.navigateTo = navigateTo;
        vm.$onInit = activate;

        function activate () {
            vm.showBrokenLinks = ng2ActiveFeatureService.isFeatureActivePure('qa_links');
            vm.showSpell = ng2ActiveFeatureService.isFeatureActivePure('qa_spellcheck');
            vm.broken_links = vm.page.dead_links_count;
            vm.broken_images = vm.page.dead_images_count;
            vm.misspellings = vm.page.spelling_errors_confirmed_count;
            vm.total = vm.page.quality_notification_count;
            vm.totalErrors = vm.page.error_score;
            setupViolations();
        }

        function navigateTo (params) {
            vm.onClickHandler({ params: params });
        }

        function setupViolations () {
            vm.violations = [];
            if (vm.showBrokenLinks) {
                vm.violations.push({
                    title: gettextCatalog.getString('Broken Links'),
                    digit: vm.page.dead_links_count,
                    classColor: 'text-link',
                    icon: 'fas fa-unlink fa-3x',
                    params: { target: 'page-details-section-qa', subTarget: 'brokenlinks' },
                });
                vm.violations.push({
                    title: gettextCatalog.getString('Broken Images'),
                    digit: vm.page.dead_images_count,
                    classColor: 'text-link',
                    icon: 'fas fa-file-image fa-3x',
                    params: { target: 'page-details-section-qa', subTarget: 'brokenimages' },
                });
            }
            if (vm.showSpell) {
                vm.violations.push({
                    title: gettextCatalog.getString('Potential Misspellings'),
                    digit: vm.page.spelling_errors_potential_count,
                    classColor: 'text-link',
                    icon: 'fas fa-language fa-3x',
                    params: { target: 'page-details-section-qa', subTarget: 'potential' },
                });
                vm.violations.push({
                    title: gettextCatalog.getString('Misspellings'),
                    digit: vm.page.spelling_errors_confirmed_count,
                    classColor: 'text-link',
                    icon: 'fas fa-language fa-3x',
                    params: { target: 'page-details-section-qa', subTarget: 'misspellings' },
                });
            }
        }
    }
})();
