import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { MonTableCollection } from '@monsido/ng2/models/table-collection.interface';
import { BaseRepo } from '../base-repo/base-repo';
import { removeSeoMissingMetaKeywordUtil } from '@client/app/modules/seo/utils/remove-seo-missing-meta-keyword.util';

type SeoRepoBaseRequestType = {
    domain_id?: number,
    group_id?: number,
}

type SeoRepoRequestType = SeoRepoBaseRequestType & {
    page: number,
    page_size: number,
    sort_by: string,
    sort_dir: string,
};

type SeoRepoGetAllRequestType = SeoRepoRequestType & { status: string };

type SeoRepoGetAllPagesRequestType = SeoRepoRequestType & { 'types[]': string[] };

type SeoRepoGetIssuePagesRequestType = SeoRepoRequestType & { issue_id: number };
export type SeoRepoType = {
    active: boolean,
    classification: number,
    created_at: string,
    id: number,
    ignored: boolean,
    name: string,
    pages_count: number,
    updated_at: string
};

type SeoRepoUpdateResponseType = Omit<SeoRepoType, 'pages_count' | 'ignored'>;

type SeoRepoContentResponseType = {
    page_count: number,
    title: string
};

type SeoRepoPageType = {
    accessibility_checks_with_errors_count: number,
    created_at: string,
    dead_images_count: number,
    dead_links_count: number,
    detected_language: string,
    domain_id: number,
    error_score: number,
    error_searches_count: number,
    fixed_images_count: number,
    fixed_links_count: number,
    hit_score: number,
    hits: number,
    id: number,
    ignored_images_count: number,
    ignored_links_count: number,
    labels: string[],
    language: string,
    language_override: null,
    lastcheck: string,
    link_score: number,
    mrank: number,
    no_index: false,
    notification_count: number,
    policy_required_count: number,
    policy_search_count: number,
    policy_violation_count: number,
    priority_score: number,
    priority_score_as_text: string,
    quality_notification_count: number,
    quality_notifications_not_searches: number,
    readability: number,
    readability_level: string,
    readability_reason: string,
    searches_count: number,
    seo_issues_count: number,
    spelling_errors_confirmed_count: number,
    spelling_errors_count: number,
    spelling_errors_potential_count: number,
    title: string,
    type: string
    updated_at: string,
    url: string
};

type SeoRepoSummeryType = {
    compliance_percentage: number,
    pages_count: number,
    pages_with_seo_errors: number,
    seo_errors: number,
    seo_errors_alerts_count: number,
    seo_errors_infos_count: number,
    seo_errors_technicals_count: number,
    seo_errors_warnings_count: number
};


@Injectable({
    providedIn: 'root',
})
export class SeoRepoService extends BaseRepo {

    constructor (private apiService: ApiService, protected sessionService: SessionService) {
        super();
    }

    async getAll (params: SeoRepoGetAllRequestType): Promise<MonTableCollection<SeoRepoType>> {
        params = this.setGroupId(params);
        let result = await this.apiService.get<MonTableCollection<SeoRepoType>>(`domains/${this.getDomainId(params.domain_id)}/seo/issues/`, params);
        result = removeSeoMissingMetaKeywordUtil(result);
        return result;
    }

    update (issueId: number, params: SeoRepoType & SeoRepoBaseRequestType): Promise<SeoRepoUpdateResponseType> {
        params = this.setGroupId(params);
        return this.apiService.patch(`domains/${this.getDomainId(params.domain_id)}/seo/issues/` + issueId, params);
    }

    getAllPages (params: SeoRepoGetAllPagesRequestType): Promise<SeoRepoPageType[]> {
        params = this.setGroupId(params);
        return this.apiService.get(`domains/${this.getDomainId(params.domain_id)}/seo/pages`, params);
    }

    getSummary (params?: SeoRepoBaseRequestType): Promise<SeoRepoSummeryType> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.get(`domains/${this.getDomainId(params?.domain_id)}/seo/summary`, params);
    }

    getSeoIssuePages (issueId: number, params: SeoRepoGetIssuePagesRequestType): Promise<SeoRepoPageType[]> {
        params = this.setGroupId(params);
        return this.apiService.get(`domains/${this.getDomainId(params.domain_id)}/seo/issues/${issueId}/pages`, params);
    }

    getIssueContent (issueId: number, params: SeoRepoRequestType): Promise<MonTableCollection<SeoRepoContentResponseType[]>> {
        params = this.setGroupId(params);
        return this.apiService.get(`domains/${this.getDomainId(params.domain_id)}/seo/issues/${issueId}/content`, params);
    }
}
