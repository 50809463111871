(function () {
    'use strict';

    angular.module('modules.export').service('exporterPerformanceAuditsParser', exporterPerformanceAuditsParser);

    exporterPerformanceAuditsParser.$inject = [
        'gettextCatalog',
        'ng2PagePerformanceAuditInfoService',
        'PagePerformanceAuditImpactService',
    ];
    /* @ngInject */
    function exporterPerformanceAuditsParser (
        gettextCatalog,
        ng2PagePerformanceAuditInfoService,
        PagePerformanceAuditImpactService,
    ) {
        var service = {
            parse: parse,
        };

        return service;

        // ///////////////

        function getHeadlines () {
            var headlines = [
                gettextCatalog.getString('Audit'),
                gettextCatalog.getString('Difficulty'),
                gettextCatalog.getString('Priority'),
            ];

            return headlines;
        }

        function parse (data) {
            var csv = [];
            var entry = [];
            if (!data || data.length === 0) {
                return csv;
            }
            csv.push(getHeadlines());

            var currentData; var auditTitle;
            for (var i = 0; i < data.length; i++) {
                currentData = data[i];
                if (currentData.displayValue) {
                    auditTitle = currentData.title + ' - ' + currentData.displayValue;
                } else {
                    auditTitle = currentData.title;
                }
                entry = [];
                entry.push(auditTitle);
                entry.push(ng2PagePerformanceAuditInfoService.getDifficulty(currentData.id));
                entry.push(PagePerformanceAuditImpactService.getImpactTranslation(currentData));
                csv.push(entry);
            }
            return csv;
        }
    }
})();
