(function () {
    'use strict';
    /**
     * @memberof modules.accessibility
     * @ngdoc service
     * @name PrivacyDialogsFastTrackService
     * @description Manage Logical operations used by the Fast Track Component
     */
    angular
        .module('modules.data_privacy.dialogs')
        .service('PrivacyDialogsFastTrackService', PrivacyDialogsFastTrackService);

    PrivacyDialogsFastTrackService.$inject = ['ng2FastTrackSessionService', 'Lodash', 'ng2DataPrivacyService'];
    /* @ngInject*/
    function PrivacyDialogsFastTrackService (ng2FastTrackSessionService, Lodash, ng2DataPrivacyService) {
        return {
            setupSession: setupSession,
            clear: clear,
            getAffectedPagesCount: getAffectedPagesCount,
            getInfoTypeTranslation: getInfoTypeTranslation,
        };

        /**
         * @memberOf PrivacyDialogsFastTrackService
         * @desc Clears Session Variables stored in Fast Track
         */
        function clear () {
            ng2FastTrackSessionService.clear();
        }

        /**
         * @memberOf PrivacyDialogsFastTrackService
         * @desc Setup Session Variables to be used in Fast Track
         */
        function setupSession () {
            ng2FastTrackSessionService.setSnippetSourceCodeProperty('content');
            ng2FastTrackSessionService.setSnippetComplianceProperty('effect_on_compliance');
            ng2FastTrackSessionService.setSnippetIssuesProperty('issueCount');
        }

        /**
         * @memberOf PrivacyDialogsFastTrackService
         * @desc Get the highest amount of affected pages found from the Info Types collection
         * @param infoTypes - Info Types Collection
         */
        function getAffectedPagesCount (infoTypes) {
            return Lodash.reduce(
                infoTypes,
                function (num, infoType) {
                    return Math.max(infoType.pages_count, num);
                },
                0,
            );
        }

        /**
         * @memberOf PrivacyDialogsFastTrackService
         * @desc Get the translation for the info type group
         * @param infoType - The info type object
         */
        function getInfoTypeTranslation (infoType) {
            return ng2DataPrivacyService.formatText(infoType.group || 'other');
        }

        // PROTECTED
    }
})();
