(function () {
    'use strict';

    angular.module('app.layout').component('sideMenuQuality', {
        template: '<side-menu menu="vm.menu"></side-menu>',
        controller: SideMenuController,
        controllerAs: 'vm',
    });

    SideMenuController.$inject = ['gettextCatalog', 'ng2SessionService', '$filter', 'ng2ActiveFeatureService'];
    function SideMenuController (gettextCatalog, ng2SessionService, $filter, ng2ActiveFeatureService) {
        var vm = this;

        vm.$onInit = activate;

        // ///////////////////////////////////////

        function activate () {
            vm.onlyQaSummaryAvailable = ng2ActiveFeatureService.isFeatureActive('qa_summary');
            vm.menu = [
                {
                    title: gettextCatalog.getString('Summary'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'QA', 'SUMMARY']),
                    href: 'base.customer.domain.qa.summary',
                },
            ];

            contentWithQAErrors();
            brokenLinks();
            spelling();
            readability();
        }

        function contentWithQAErrors () {
            if (!vm.onlyQaSummaryAvailable) {
                vm.menu.push({
                    title: gettextCatalog.getString('Content with QA Errors'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'QA', 'CONTENT']),
                    href: 'base.customer.domain.qa.pages',
                });
            }
        }

        function spelling () {
            if (ng2ActiveFeatureService.isFeatureActivePure('qa_spellcheck') && !vm.onlyQaSummaryAvailable) {
                vm.menu.push({
                    title: gettextCatalog.getString('Spellcheck'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'QA', 'SPELLCHECK']),
                    href: 'base.customer.domain.qa.spellcheck.index',
                    activeRoutes: [
                        'base.customer.domain.qa.spellcheck.pages',
                        'base.customer.domain.qa.spellcheck.potential',
                        'base.customer.domain.qa.spellcheck.confirmed',
                        'base.customer.domain.qa.spellcheck.dictionary',
                        'base.customer.domain.qa.spellcheck.ignored_misspellings',
                        'base.customer.domain.qa.spellcheck.qa_errors',
                        'base.customer.domain.qa.spellcheck.dashboard',
                    ],
                    subs: [
                        {
                            title: gettextCatalog.getString('Spellcheck Summary'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'QA', 'SPELLCHECK_SUMMARY']),
                            href: 'base.customer.domain.qa.spellcheck.dashboard',
                        },
                        {
                            title: gettextCatalog.getString('Pages with Misspellings'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'QA', 'SPELLCHECK_PAGES']),
                            href: 'base.customer.domain.qa.spellcheck.pages',
                        },
                        {
                            title: gettextCatalog.getString('Misspellings'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'QA', 'MISSPELLINGS']),
                            href: 'base.customer.domain.qa.spellcheck.confirmed',
                        },
                        {
                            title: gettextCatalog.getString('Potential Misspellings'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'QA', 'POTENTIAL_MISSPELLINGS']),
                            href: 'base.customer.domain.qa.spellcheck.potential',
                        },
                        {
                            title: gettextCatalog.getString('Dictionary'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'QA', 'DICTIONARY']),
                            href: 'base.customer.domain.qa.spellcheck.dictionary',
                        },
                        {
                            title: gettextCatalog.getString('Ignored Misspellings'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'QA', 'DICTIONARY']),
                            href: 'base.customer.domain.qa.spellcheck.ignored_misspellings',
                        },
                    ],
                });
            }
        }

        function brokenLinks () {
            if (ng2ActiveFeatureService.isFeatureActivePure('qa_links')) {
                vm.menu.push({
                    title: gettextCatalog.getString('Links'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'QA', 'LINKS']),
                    href: 'base.customer.domain.qa.broken.links',
                    activeRoutes: [
                        'base.customer.domain.qa.broken.links',
                        'base.customer.domain.qa.broken.images',
                        'base.customer.domain.qa.broken.pages',
                    ],
                    subs: [
                        {
                            title: gettextCatalog.getString('Content with Broken Links'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'QA', 'CONTENT_BROKEN_LINKS']),
                            href: 'base.customer.domain.qa.broken.pages',
                        },
                        {
                            title: gettextCatalog.getString('Broken Links'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'QA', 'BROKEN_LINKS']),
                            href: 'base.customer.domain.qa.broken.links',
                        },
                        {
                            title: gettextCatalog.getString('Broken Images'),
                            icon: $filter('monIcons')(['SIDEMENUES', 'QA', 'BROKEN_IMAGES']),
                            href: 'base.customer.domain.qa.broken.images',
                        },
                    ],
                });
            }
        }

        function readability () {
            if (ng2SessionService.domain.features.readability_test !== null && ng2ActiveFeatureService.isFeatureActivePure('admin_readability')) {
                vm.menu.push({
                    title: gettextCatalog.getString('Readability'),
                    icon: $filter('monIcons')(['SIDEMENUES', 'QA', 'READABILITY']),
                    href: 'base.customer.domain.qa.readability.dashboard',
                });
            }
        }
    }
})();
