(function () {
    'use strict';

    angular.module('pages.qa', [
        'pages.qa.broken',
        'pages.qa.spellcheck',
        'pages.qa.readability',
        'app.core',
    ]);
})();
