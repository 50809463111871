import { Injectable } from '@angular/core';
import { MonModalService } from '@client/app/modules/modals/services/mon-modal/mon-modal.service';
import { AdminDomainsService } from '@client/app/pages/admin/domains/admin-domains-service/admin-domains.service';
import { DomainRepoGetParamsType } from '@client/app/services/api/domain-repo/domain-repo.service';
import { DomainService } from '@client/app/services/entities/domain/domain.service';
import { MeService } from '@client/app/services/entities/me/me.service';
import { TotalDomainsService } from '@client/app/services/total-domains/total-domains.service';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { LocalStorageService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { MON_EVENTS } from '@monsido/ng2/core/constants/mon-events.constant';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { Domain } from '@monsido/ng2/modules/models/api/domain';
import { DomainGroup } from '@monsido/ng2/modules/models/api/domain-group';
import { Login } from '@monsido/ng2/modules/models/api/interfaces/domain.interface';
import { MonEventService } from '@monsido/ng2/services/mon-event/mon-event.service';
import { ErrorHandler } from '../error-handler/error-handler';
import { CrawlHistoryInfoService } from '../crawl-history-info/crawl-history-info.service';

@Injectable({
    providedIn: 'root',
})
export class CoreDomainService {
    updateDomainFailedAttempts = 0;
    getDomainsIntervalId: number | null = null;
    updateDomainIntervalId: number | null = null;

    constructor (
        private sessionService: SessionService,
        private domainService: DomainService,
        private errorHandler: ErrorHandler,
        private monEventService: MonEventService,
        private translateService: TranslateService,
        private monModalService: MonModalService,
        private adminDomainsService: AdminDomainsService,
        private meService: MeService,
        private totalDomainsService: TotalDomainsService,
        private localStorageService: LocalStorageService,
        private crawlHistoryInfoService: CrawlHistoryInfoService,
    ) {}

    disposeDomains (): void {
        this.totalDomainsService.resetDomains();
        this.cancelDomainPromise();
    }

    setDomain (domain: Domain | null): void {
        this.sessionService.domain = domain;

        if (domain?.crawl_history?.accessibility_errors_only != null) {
            this.crawlHistoryInfoService.setAccessibilityErrorsOnly(domain.crawl_history.accessibility_errors_only);
        } else {
            this.crawlHistoryInfoService.setAccessibilityErrorsOnly(Boolean(domain?.features.accessibility_errors_only));
        }
        if (this.sessionService.domain !== null && this.sessionService.domainGroup !== null) {
            const domainGroups = this.sessionService.domain?.domain_groups?.map(function (group) {
                return group.id;
            });
            if (domainGroups?.indexOf(this.sessionService.domainGroup.id) === -1) {
                this.removeDomainGroup();
            }
        }
        this.sessionService.domainObs.next(domain);
    }

    setDomainGroup (domainGroup: DomainGroup | null): void {
        this.sessionService.domainGroup = domainGroup;
        this.localStorageService.setItem('domainGroup', JSON.stringify(domainGroup));
    }

    clearDomain (): void {
        this.sessionService.domain = null;
    }

    removeDomainGroup (): void {
        this.sessionService.domainGroup = null;
        this.localStorageService.setItem('domainGroup', 'null');
    }

    setupDomainGroupAfterReload (): void {
        let domainGroup: DomainGroup | null = null;
        try {
            const domainGroupStr = this.localStorageService.getItem('domainGroup');
            if (domainGroupStr) {
                domainGroup = JSON.parse(domainGroupStr);
            }
        } catch (e) {
            domainGroup = null;
        }
        this.sessionService.domainGroup = domainGroup;
    }

    async getDomains (): Promise<void> {
        const params = { page_size: 100, mini: true };
        const domainCollection = [];

        this.totalDomainsService.requestAllDomains({
            params,
            domainCollection,
            isRecursive: false,
        })
            .finally(() => {
                this.cancelDomainPromise();
                this.getDomainsIntervalId = window.setInterval(() => {
                    this.getDomains();
                }, 900000);
            });
    }

    disposeDomain (): void {
        if (!this.sessionService.domainObs.isStopped) {
            this.sessionService.domainObs.unsubscribe();
        }
    }

    cancelDomainPromise (): void {
        if (this.getDomainsIntervalId !== null) {
            window.clearInterval(this.getDomainsIntervalId);
            this.getDomainsIntervalId = null;
        }
    }

    refreshDomain (): Promise<void> {
        return this.updateDomain(true);
    }

    init (): void {
        this.totalDomainsService.resetDomains();
        this.cancelUpdateDomainPromise();
        this.updateDomainIntervalId = window.setInterval(() => {
            this.updateDomain();
        }, 90000);
    }

    openDomainForm (domain: Domain, domainFormComponent: string): Promise<Domain> {
        return new Promise(resolve => {
            domain = domain || {};
            const params = {
                body: domainFormComponent,
                size: 'sm',
                data: {
                    domain: domain,
                },
            };

            const callback = (callbackDomain: Domain): void => {
                resolve(callbackDomain);
            };

            this.monEventService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        });
    }

    deleteDomain (domain: Domain): Promise<void> {
        const msg = this.translateService.getString(
            'You are about to delete the domain <span class=\'text-link\'>{{domainTitle}}</span>' +
                              'please type <code>Delete</code> to confirm this action' +
                              '<div class=\'alert alert-danger py-1 px-1 mb-0 mt-3\'>' +
                              'By deleting this domain all domain groups related to this domain, will also be deleted</div>',
            { domainTitle: domain.title },
        );

        return new Promise(async (resolve, reject) => {
            try {
                const result = await this.monModalService.prompt(msg, undefined);
                if ('delete' !== result.toLowerCase().trim()) {
                    reject(result);
                } else {
                    await this.domainService.destroy(domain.id);
                    resolve();
                }
            } catch (e) {
                reject(e);
            }
        });
    }

    toggleDomainPinned (domain: Domain): Promise<Domain> {
        return this.meService.updateDomain(domain.id, {
            favorite: !domain.favorite,
        });
    }

    openScriptGuide (domain: Domain, bodyComponentName: string): void {
        const params = {
            body: bodyComponentName,
            size: 'sm',
            data: { domain: domain },
        };

        const callback = (): void => {
            this.adminDomainsService.updateDomainSettings(domain);
        };
        this.monEventService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
    }


    showCmsUrl (domain: Domain): boolean {
        return ['sitecore', 'omniupdate', 'publicera', 'sitefinity', 'custom', 'cascade', 'aem', 'acos'].indexOf(domain.cms) !== -1;
    }

    showOptionalCmsUrl (domain: Domain): boolean {
        return ['drupal', 'kentico', 'episerver'].indexOf(domain.cms) !== -1;
    }

    showSpecialKey (domain: Domain): boolean {
        return ['special'].indexOf(domain.cms) !== -1;
    }

    showPrefixPath (domain: Domain): boolean {
        return ['wordpress_multisite'].indexOf(domain.cms) !== -1;
    }

    showTemplatePath (domain: Domain): boolean {
        return ['umbraco'].indexOf(domain.cms) !== -1;
    }

    getTemplatePlaceholder (domain: Domain): string {
        if (['umbraco'].indexOf(domain.cms) !== -1) {
            return '/umbraco/umbraco.aspx?app=content&rightAction=editContent&id=<%= id %>#content';
        }
        return '';
    }

    setupScanLogin (loginOptions: Login): Login | null {
        let login: Login | null = null;
        if (loginOptions && loginOptions.type !== 'none') {
            const optionsType = loginOptions.type;
            login = {
                type: optionsType,
                verify: loginOptions.verify,
            };
            login[optionsType] = loginOptions[optionsType];
        }

        return login;
    }

    openScanDetails (): void {
        const params = {
            body: 'scanDetailsDialog',
            size: 'sm',
            classes: 'fade-animation middle center',
            data: {
                domainObservable: this.sessionService.domainObs,
            },
        };
        this.monEventService.run(MON_EVENTS.LOAD_DIALOG, { params });
    }

    hasCrawlStatus (): boolean {
        return this.sessionService.domain !== null && this.sessionService.domain.crawl_status !== null;
    }

    getDomainCrawlStatus (): number {
        if (this.sessionService.domain !== null && this.sessionService.domain.crawl_status !== null) {
            return this.sessionService.domain?.crawl_status?.progress || 0;
        } else {
            return 0;
        }
    }

    private async updateDomain (force = false): Promise<void> {
        if (force || this.sessionService.domain !== null) {
            const params: DomainRepoGetParamsType = {};
            const groupId = this.sessionService.domainGroup ? this.sessionService.domainGroup.id : null;
            if (groupId) {
                params.group_id = groupId;
            }
            if (!this.sessionService.domain) {
                return;
            }
            return this.domainService.get(this.sessionService.domain.id, params).then(
                (domain) => {
                    this.updateDomainFailedAttempts = 0;
                    // Check if domain changed after fetching details
                    if (this.sessionService.domain !== null && domain.id === this.sessionService.domain.id) {
                        this.setDomain(domain);
                    }
                },
                (error) => {
                    this.updateDomainFailedAttempts++;
                    if (this.updateDomainFailedAttempts < 3) {
                        this.updateDomain();
                    } else {
                        this.errorHandler.noopError(error, 'warning');
                    }
                },
            );
        }
    }

    private cancelUpdateDomainPromise (): void {
        if (this.updateDomainIntervalId !== null) {
            window.clearInterval(this.updateDomainIntervalId);
            this.updateDomainIntervalId = null;
        }
    }

}
