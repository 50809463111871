(function () {
    'use strict';

    angular.module('pages.accessibility').component('guidelinesDialogsChecksPages', {
        templateUrl: 'app/pages/accessibility/guidelines/dialogs/checks/pages/pages.html',
        controller: PagesController,
        controllerAs: 'vm',
        bindings: {
            checkId: '<',
            refreshChecks: '&',
        },
    });
    PagesController.$inject = [
        'ng2AccessibilityChecksRepoService',
        'ng2SessionService',
        '$controller',
        'accessibilityService',
        'ng2MonEventsService',
        'ENTITY_CONSTANT',
        'BaseEntityService',
        'ng2ActiveFeatureService',
    ];

    function PagesController (
        ng2AccessibilityChecksRepoService,
        ng2SessionService,
        $controller,
        accessibilityService,
        ng2MonEventsService,
        ENTITY_CONSTANT,
        BaseEntityService,
        ng2ActiveFeatureService,
    ) {
        var vm = this;

        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.ignoreCheck = ignoreCheck;
        vm.allowIgnoreAction = false;
        vm.$onDestroy = onDestroy;

        function activate () {
            vm.eventId = ng2MonEventsService.addListener(
                ENTITY_CONSTANT.PAGE_UPDATED_EVENT,
                BaseEntityService.updateEntryInArray(vm, 'pages', 'id'),
            );
            vm.viewsColumIsAvailable = ng2ActiveFeatureService.isFeatureActive('script_setup_guide');
            vm.pageDetailsIsAvailable = ng2ActiveFeatureService.isFeatureActive('page_details');
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.onPageDetailsClose = angular.noop; // Do nothing when closing page details
            vm.pages = [];
            vm.currentSort = { by: 'hits', direction: 'desc' };
            getPage();
            vm.getLabels();
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: 4,
                sort_by: 'priority_score',
                sort_dir: 'desc',
            };

            vm.progress = ng2AccessibilityChecksRepoService.getAllPages(vm.checkId, params).then(function (data) {
                vm.allowIgnoreAction = !ng2SessionService.isDomainGroup();
                vm.pages = data;
            }, angular.noop);
        }

        function ignoreCheck () {
            accessibilityService.updateCheck(vm.checkId, { ignored: true })
                .then(
                    () => vm.refreshChecks(),
                    angular.noop,
                );
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.eventId);
        }
    }
})();
