import PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG from '@client/app/modules/page-fix/constants/page-fix-accessibility-check-config.json';
import { PAGE_FIX_ACCESSIBILITY_CHECK_TAB } from 'app/modules/page-fix/constants/page-fix-accessibility-check-tab';

import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { PageFixType } from '@client/app/services/api/pagefix-repo/page-fix-repo.service';

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
interface LightDomainPageFixDashboardPageFixModel extends PageFixType {
    translatedCategories: string[];
}
// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
class LightDomainPageFixDashboardPageFixModel {
    translatedCategories: string[] = [];
    constructor (
        private translateService: TranslateService,
        input: PageFixType,
    ) {
        Object.assign(this, input);
        const checkId = input.change.check_id;
        if (!checkId) {
            return;
        }
        const config = PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[checkId];
        if (config && config.work_types) {
            for (let i = 0; i < config.work_types.length; i++) {
                this.translatedCategories.push(this.getCategoryTranslation(config.work_types[i]));
            }
        }
    }

    getImage (): string {
        const checkId = this.change.check_id;
        if (!checkId) {
            return '';
        }
        const config = PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG[checkId];
        return config.imagePath;
    }

    private getCategoryTranslation (category: string): string {
        switch (category) {
            case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.WEBSITE_STRUCTURE:
                return this.translateService.getString('Website Structure');
            case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.DESIGN:
                return this.translateService.getString('Design');
            case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.LINKS:
                return this.translateService.getString('Links');
            case PAGE_FIX_ACCESSIBILITY_CHECK_TAB.MEDIA:
                return this.translateService.getString('Media');
            default:
                return '';
        }
    }
}

@Injectable({
    providedIn: 'root',
})
export class LightDomainPageFixDashboardPageFixModelService {
    constructor (
        private translateService: TranslateService,
    ) {}

    createModel (input: PageFixType): LightDomainPageFixDashboardPageFixModel {
        return new LightDomainPageFixDashboardPageFixModel(
            this.translateService,
            input,
        );
    }
}
