(function () {
    'use strict';
    angular.module('modules.light.pages.domain.page_fix').component('lightDomainPageFixDashboard', {
        templateUrl:
            'app/modules/light/pages/domain/page-fix/lightDomainPageFixDashboard/lightDomainPageFixDashboard.html',
        controller: LightDomainPageFixDashboardController,
        controllerAs: 'vm',
    });

    LightDomainPageFixDashboardController.$inject = [
        '$q',
        'PAGE_FIX_FIXABLE_TYPES',
        'ng2PageFixRepoService',
        'gettextCatalog',
        '$filter',
        'LightDomainPageFixDashboardService',
        'ng2AccessibilityChecksRepoService',
    ];

    /* @ngInject */
    function LightDomainPageFixDashboardController (
        $q,
        PAGE_FIX_FIXABLE_TYPES,
        ng2PageFixRepoService,
        gettextCatalog,
        $filter,
        LightDomainPageFixDashboardService,
        ng2AccessibilityChecksRepoService,
    ) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.loading = false;
            vm.pageFixCategories = [];
            vm.latestPageFixes = [];
            vm.accessibilityChecksDictionary = {};

            loadPageFixes();
        }

        function loadPageFixes () {
            vm.loading = true;
            $q.all([getPageFixes(), getAccessibilityChecks()])
                .then(function (values) {
                    var pageFixes = values[0];
                    var accessibilityChecks = values[1];
                    vm.pageFixCategories = LightDomainPageFixDashboardService.getPageFixesByCategory(pageFixes);
                    setupChart(pageFixes);
                    setupAccessibilityChecksDictionary(accessibilityChecks);
                    vm.latestPageFixes = pageFixes.slice(0, 5);
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        // PROTECTED

        function getPageFixes () {
            var params = {
                page_size: 0,
                fixable_type: PAGE_FIX_FIXABLE_TYPES.accessibility_check,
            };

            return ng2PageFixRepoService.getAll(params).then(LightDomainPageFixDashboardService.parseToPageFixModels);
        }

        function getAccessibilityChecks () {
            return ng2AccessibilityChecksRepoService.getAll();
        }

        function setupAccessibilityChecksDictionary (accessibilityChecks) {
            for (var i = 0; i < accessibilityChecks.length; i++) {
                var check = accessibilityChecks[i];
                vm.accessibilityChecksDictionary[check.id] = check;
            }
        }

        function setupChart (pageFixes) {
            var pageFixLen = pageFixes.length;
            var pageFixCategoryTotal = vm.pageFixCategories.reduce(function (acc, pageFixCategory) {
                return acc + pageFixCategory.totalFixes;
            }, 0);
            var percentage = pageFixLen / pageFixCategoryTotal;

            if (isNaN(percentage)) {
                percentage = 0;
            }

            vm.doughnutData = [pageFixLen, pageFixCategoryTotal - pageFixLen];
            vm.doughnutColors = ['#299BF7', '#dcdcdc'];
            vm.doughnutLabels = [gettextCatalog.getString('PageFixes'), gettextCatalog.getString('PageFixes left')];
            vm.doughnutOptions = {
                centerText: {
                    percentageLabel: $filter('monNumber')(percentage, '0.[00] %'),
                    totalLabel: gettextCatalog.getString('PageCorrect fixes applied'),
                    totalFontSize: 150,
                },
                cutoutPercentage: 80,
            };
        }
    }
})();
