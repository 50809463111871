import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { PageModel, PageModelFactoryService } from '../../../../models/page-model-factory.service';
import moment from 'moment';
import { Page } from '@monsido/modules/page-details/models';
import { cloneDeep } from 'lodash';

type DemoStatitisticsVisitor = {
    date: string,
    visits: string,
    url: string | null,
    visitors: string | null,
    uniq_visitors: string,
    label: string | null,
    bounce_rate: string,
    bounces: string | null
    actions: string,
    avg_time: string,
    pageviews: string
}

type DemoStatisticsSummaries = {
    date: string,
    visits: string,
    visitors: number | null,
    avg_time: string,
    bounce_rate: string,
    avg_actions: string | null,
    pageviews: string,
    uniq_pageviews: string | null,
    bounce_count: string
}

@Injectable({
    providedIn: 'root',
})
export class DemoPageRepoService {
    hostNameRegex = /^https?:\/\/(?:w{3}\.)?([a-z0-9\.-]+)\.(?:[a-z\.]{2,10})(?:[a-z\.-])/;
    pages: Page[] = [
        {
            id: 1,
            domain_id: 1,
            title: 'Lorem ipsum',
            url: 'https://sample.tld/path_to_file.pdf',
            mrank: 1,
            hit_score: 1,
            link_score: 11,
            error_score: 1,
            priority_score: 11,
            detected_language: 'da_DK',
            language_override: null,
            no_index: false,
            hits: 0,
            type: 'pdf',
            dead_links_count: 1,
            ignored_links_count: 2,
            fixed_links_count: 3,
            dead_images_count: 4,
            ignored_images_count: 5,
            fixed_images_count: 6,
            seo_issues_count: 7,
            spelling_errors_count: 8,
            notification_count: 9,
            spelling_errors_confirmed_count: 10,
            spelling_errors_potential_count: 11,
            accessibility_checks_with_errors_count: 12,
            quality_notification_count: 14,
            quality_notifications_not_searches: 15,
            cms_url: 'https://sample.tld/path_to_file.pdf',
            words_count: 1281,
            language: 'da_DK',
            priority_score_as_text: 'low',
            readability_words_count: 44,
            readability_level: 'na',
            searches_count: 16,
            error_searches_count: 17,
            policy_violation_count: 18,
            policy_search_count: 19,
            policy_required_count: 20,
            lastcheck: '2018-12-20T05:37:00+01:00',
            created_at: '2018-06-19T05:15:47+02:00',
            updated_at: '2018-12-20T11:03:42+01:00',
            labels: [],
            data_protection_violations_counts: {
                violations_count: 21,
                ignored_violations: 22,
                fixed_violations: 23,
                active_violations: 24,
            },
            readability: null,
            readability_reason: '',
            performance_scores: {
                first_contentful_paint: 25,
                time_to_interactive: 26,
                first_meaningful_paint: 27,
            },
        },
        {
            id: 2,
            domain_id: 1,
            title: 'Ipsum dolor',
            url: 'https://sample.tld/files/dolor.pdf',
            mrank: 28,
            hit_score: 29,
            link_score: 30,
            error_score: 31,
            priority_score: 32,
            detected_language: 'da_DK',
            language_override: null,
            no_index: false,
            hits: 33,
            type: 'pdf',
            dead_links_count: 34,
            ignored_links_count: 35,
            fixed_links_count: 36,
            dead_images_count: 37,
            ignored_images_count: 38,
            fixed_images_count: 39,
            seo_issues_count: 40,
            spelling_errors_count: 41,
            notification_count: 42,
            spelling_errors_confirmed_count: 43,
            spelling_errors_potential_count: 44,
            accessibility_checks_with_errors_count: 45,
            quality_notification_count: 47,
            quality_notifications_not_searches: 48,
            cms_url: 'https://sample.tld/files/dolor.pdf',
            readability_reason: '',
            words_count: 1040,
            language: 'da_DK',
            priority_score_as_text: 'low',
            readability_level: 'na',
            searches_count: 49,
            error_searches_count: 50,
            policy_violation_count: 51,
            policy_search_count: 52,
            policy_required_count: 53,
            lastcheck: '2018-12-20T05:37:00+01:00',
            created_at: '2017-10-16T14:51:04+02:00',
            updated_at: '2018-12-20T11:01:05+01:00',
            labels: [],
            performance_scores: {
                first_contentful_paint: 54,
                first_meaningful_paint: 55,
                time_to_interactive: 56,
            },
            readability: null,
            data_protection_violations_counts: {
                violations_count: 57,
                active_violations: 58,
                fixed_violations: 59,
                ignored_violations: 60,
            },
            readability_words_count: 61,
        },
        {
            id: 3,
            domain_id: 1,
            title: 'Etiam feugiat vehicula dolor et rutrum. Aenean at tellus',
            url: 'https://sample.tld/sites/default/files/Etiam_.pdf',
            mrank: 62,
            readability_reason: '',
            hit_score: 63,
            link_score: 64,
            error_score: 65,
            priority_score: 66,
            detected_language: 'da_DK',
            language_override: null,
            no_index: false,
            hits: 67,
            type: 'pdf',
            dead_links_count: 68,
            ignored_links_count: 69,
            fixed_links_count: 70,
            dead_images_count: 71,
            ignored_images_count: 72,
            fixed_images_count: 73,
            seo_issues_count: 74,
            spelling_errors_count: 75,
            notification_count: 76,
            spelling_errors_confirmed_count: 77,
            spelling_errors_potential_count: 78,
            accessibility_checks_with_errors_count: 79,
            quality_notification_count: 81,
            quality_notifications_not_searches: 82,
            cms_url: 'https://sample.tld/sites/default/files/Etiam_.pdf',
            words_count: 1108,
            language: 'da_DK',
            priority_score_as_text: 'low',
            readability_level: 'na',
            searches_count: 83,
            error_searches_count: 84,
            policy_violation_count: 85,
            policy_search_count: 86,
            policy_required_count: 87,
            lastcheck: '2018-12-20T05:37:00+01:00',
            created_at: '2018-02-25T09:36:29+01:00',
            updated_at: '2018-12-20T11:01:24+01:00',
            labels: [],
            data_protection_violations_counts: {
                violations_count: 88,
                ignored_violations: 89,
                fixed_violations: 90,
                active_violations: 91,
            },
            readability: null,
            performance_scores: {
                first_contentful_paint: 92,
                time_to_interactive: 93,
                first_meaningful_paint: 94,
            },
            readability_words_count: 95,
        },
        {
            id: 4,
            domain_id: 1,
            title: 'Etiam nec quam egestas',
            url: 'https://sample.tld/Etiam_nec_quam_egestas.pdf',
            mrank: 96,
            hit_score: 97,
            link_score: 98,
            error_score: 99,
            priority_score: 100,
            detected_language: 'da_DK',
            language_override: null,
            no_index: false,
            hits: 101,
            type: 'pdf',
            dead_links_count: 102,
            readability_reason: '',
            ignored_links_count: 103,
            fixed_links_count: 104,
            dead_images_count: 105,
            ignored_images_count: 106,
            fixed_images_count: 107,
            seo_issues_count: 108,
            spelling_errors_count: 109,
            notification_count: 110,
            spelling_errors_confirmed_count: 111,
            spelling_errors_potential_count: 112,
            accessibility_checks_with_errors_count: 113,
            quality_notification_count: 115,
            quality_notifications_not_searches: 116,
            cms_url: 'https://sample.tld/Etiam_nec_quam_egestas.pdf',
            words_count: 1272,
            language: 'da_DK',
            priority_score_as_text: 'low',
            readability_level: 'na',
            searches_count: 117,
            error_searches_count: 118,
            policy_violation_count: 119,
            policy_search_count: 120,
            policy_required_count: 121,
            lastcheck: '2018-12-20T05:37:00+01:00',
            created_at: '2017-10-16T14:51:04+02:00',
            updated_at: '2018-12-20T11:01:05+01:00',
            labels: [],
            readability: null,
            performance_scores: {
                first_contentful_paint: 80,
                time_to_interactive: 19,
                first_meaningful_paint: 20,
            },
            data_protection_violations_counts: {
                violations_count: 1,
                active_violations: 45,
                fixed_violations: 43,
                ignored_violations: 32,
            },
            readability_words_count: 120,
        },
        {
            id: 5,
            domain_id: 1,
            title: 'Dolor as nec',
            url: 'https://sample.tld/files/dolor_as.pdf',
            mrank: 12,
            hit_score: 145,
            link_score: 12,
            error_score: 19,
            priority_score: 10,
            detected_language: 'da_DK',
            language_override: null,
            no_index: false,
            hits: 23,
            type: 'pdf',
            dead_links_count: 34,
            ignored_links_count: 43,
            fixed_links_count: 24,
            dead_images_count: 23,
            ignored_images_count: 12,
            fixed_images_count: 4,
            seo_issues_count: 23,
            spelling_errors_count: 29,
            notification_count: 23,
            readability_reason: '',
            spelling_errors_confirmed_count: 24,
            spelling_errors_potential_count: 64,
            accessibility_checks_with_errors_count: 64,
            quality_notification_count: 25,
            quality_notifications_not_searches: 456,
            cms_url: 'https://sample.tld/files/dolor_as.pdf',
            words_count: 1272,
            language: 'da_DK',
            priority_score_as_text: 'low',
            readability_level: 'na',
            searches_count: 35,
            error_searches_count: 234,
            policy_violation_count: 24,
            policy_search_count: 245,
            policy_required_count: 13,
            lastcheck: '2018-12-20T05:37:00+01:00',
            created_at: '2017-10-16T14:51:04+02:00',
            updated_at: '2018-12-20T11:01:05+01:00',
            labels: [],
            readability_words_count: 340,
            readability: null,
            data_protection_violations_counts: {
                violations_count: 30,
                ignored_violations: 20,
                fixed_violations: 2,
                active_violations: 13,
            },
            performance_scores: {
                first_contentful_paint: 2,
                first_meaningful_paint: 5,
                time_to_interactive: 9,
            },
        },
    ];
    statVisitors: Array<DemoStatitisticsVisitor[]> = ((): Array<DemoStatitisticsVisitor[]> => {
        const result: DemoStatitisticsVisitor[][] = [];
        for (let i = 0; i < 3; i++) {
            result[i] = [];
            for (let j = 0; j < 7; j++) {
                result[i].push({
                    date: moment().subtract(j + 1, 'days')
                        .toISOString(),
                    visits: '2',
                    url: null,
                    visitors: null,
                    uniq_visitors: '1',
                    label: null,
                    bounce_rate: '4%',
                    bounces: null,
                    actions: '7',
                    avg_time: '24',
                    pageviews: '120',
                });
            }
        }

        return result;
    })();

    statSummaries: DemoStatisticsSummaries[] = ((): DemoStatisticsSummaries[] => {
        const result: DemoStatisticsSummaries[] = [];
        for (let i = 0; i < 5; i++) {
            result.push({
                date: '2019-02-18T00:00:00.000+00:00',
                visits: (i + 3).toString(),
                visitors: null,
                avg_time: (i * 10 + 1).toString(),
                bounce_rate: '0%',
                avg_actions: null,
                pageviews: (i + 1).toString(),
                uniq_pageviews: null,
                bounce_count: (i + 4).toString(),
            });
        }
        return result;
    })();

    constructor (
        private sessionService: SessionService,
        private pageModelFactoryService: PageModelFactoryService,
    ) {}

    async get (pageId: number): Promise<Page | null> {
        const pages = await this.getData('get') as unknown as Page[];
        return this.findPage(pageId, pages);
    }

    async update (pageId: number): Promise<Page | null> {
        const pages = await this.getData('update') as unknown as Page[];
        return this.findPage(pageId, pages);
    }

    async getStatSummary (pageId: number): Promise<DemoStatisticsSummaries> {
        const summaries = await this.getData('getStatSummary');
        return summaries[pageId % summaries.length];
    }

    async getStatVisitors (pageId: number): Promise<DemoStatitisticsVisitor[]> {
        const visitors = await this.getData('getStatVisitors');
        return visitors[pageId % visitors.length];
    }

    isDemo (page: Page): boolean {
        return this.pages.some(function (mockPage) {
            return mockPage.id === page.id;
        });
    }

    private getData (fnName: 'update'): Promise<Page[]>
    private getData (fnName: 'get'): Promise<Page[]>
    private getData (fnName: 'getStatSummary'): Promise<DemoStatisticsSummaries[]>
    private getData (fnName: 'getStatVisitors'): Promise<Array<DemoStatitisticsVisitor[]>>
    private getData (fnName: string): Promise<unknown> {
        const delay = Math.round(1000 * Math.random());
        return new Promise(resolve => {
            setTimeout(() => {
                switch (fnName) {
                    case 'update':
                    case 'get':
                        resolve(this.parsePages(this.pages));
                        break;
                    case 'getStatSummary':
                        resolve(this.statSummaries);
                        break;
                    case 'getStatVisitors':
                        resolve(this.statVisitors);
                        break;
                    default:
                        resolve([]);
                }
            }, delay);
        });
    }

    private findPage (pageId: number, pages: Page[]): PageModel | null {
        const page = pages.find(p => p.id === pageId);
        if (page) {
            return this.pageModelFactoryService.getModel(page);
        }
        return null;
    }

    private parsePages (pages: Page[]): Page[] {
        return cloneDeep(pages).map(p => {
            p.url = p.url.replace(this.hostNameRegex, this.getDomainUrl());
            if (p.cms_url) {
                p.cms_url = p.cms_url.replace(this.hostNameRegex, this.getDomainUrl());
            }
            return p;
        });
    }

    private getDomainUrl (): string {
        if (this.sessionService.domain) {
            const domain = this.sessionService.domain;
            if (domain.url.endsWith('/')) {
                return domain.url.substring(0, domain.url.length - 1);
            }
            return domain.url;
        }

        return '';
    }

}
