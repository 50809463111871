(function () {
    'use strict';

    angular.module('plugins', [
        'plugins.deepmerge',
        'plugins.lodash',
        'plugins.scroll',
        'plugins.papa',
    ]);
})();
