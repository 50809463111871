(function () {
    'use strict';

    angular.module('models').factory('PagePerformanceAssignedProfileModel', PagePerformanceAssignedProfileModel);

    PagePerformanceAssignedProfileModel.$inject = ['Lodash', 'ng2FormPagePerformancePageService', 'gettextCatalog'];

    function PagePerformanceAssignedProfileModel (Lodash, ng2FormPagePerformancePageService, gettextCatalog) {
        function Model (attributes) {
            var defaultObj = {
                page: {
                    performance_scores: {
                        first_contentful_paint: 0,
                        first_meaningful_paint: 0,
                        time_to_interactive: 0,
                    },
                },
            };
            Lodash.mergeWith(this, defaultObj, attributes, function (objValue, srcValue) {
                return srcValue === null ? objValue : undefined;
            });
        }

        Model.prototype.getFrequencyTranslation = getFrequencyTranslation;
        return Model;

        // /////////

        function getFrequencyTranslation () {
            var vm = this;
            var frequencyOptions = ng2FormPagePerformancePageService.getFrequencyOptions();
            var frequency = Lodash.find(frequencyOptions, { value: vm.scan_frequency });
            if (frequency) {
                return frequency.name;
            }
            return gettextCatalog.getString('No Frequency');
        }
    }
})();
