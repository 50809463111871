/* eslint-disable max-len */

import { TranslateService } from '@client/app/services/translate/translate.service';
import { ReadabilityGradesType, ReadabilityGradesLixType } from './qa-readability.type';

export const getGradeTranslations = (translateService: TranslateService): ReadabilityGradesType => (
    {
        flesch_kincaid_re: {
            1: translateService.getString('Below 5th grade'),
            5: translateService.getString('5th grade'),
            6: translateService.getString('6th grade'),
            7: translateService.getString('7th grade'),
            8: translateService.getString('8th to 9th grade'),
            10: translateService.getString('10th to 12th grade'),
            15: translateService.getString('College'),
            17: translateService.getString('College graduate'),
            na: translateService.getString('No readability score'),
            language_not_supported: translateService.getString('Language not supported'),
            other: translateService.getString('Score could not be generated'),
            test_name: translateService.getString('Flesch Kincaid'),
        },
        lix: {
            'very easy': translateService.getString('Very easy'),
            easy: translateService.getString('Easy'),
            medium: translateService.getString('Medium'),
            hard: translateService.getString('Hard'),
            'very hard': translateService.getString('Very hard'),
            na: translateService.getString('No readability score'),
            test_name: translateService.getString('LIX'),
        },
    }
);

export const getGradeTooltips = (translateService: TranslateService): ReadabilityGradesType => ({
    flesch_kincaid_re: {
        1: translateService.getString('Pages that are easily understood by children below 5\'th grade'),
        5: translateService.getString('Pages that are very easy to read. Easily understood by an average 11-year-old student'),
        6: translateService.getString('Pages that are easy to read. Conversational English for consumers'),
        7: translateService.getString('Pages that are fairly easy to read'),
        8: translateService.getString('Pages that are in plain English. Easily understood by 13- to 15-year-old students'),
        10: translateService.getString('Pages that are fairly difficult to read'),
        15: translateService.getString('Pages that are difficult to read'),
        17: translateService.getString('Pages that are very difficult to read. Best understood by university graduates'),
        na: translateService.getString('Pages we could not determine Readability score'),
        language_not_supported: translateService.getString('The language of the page is not recognized as English. The Flesch-Kincaid test only works for English language. Change to the LIX test if you wish to generate scores for non-English pages.'),
        other: translateService.getString('We could not generate an accurate score for these pages. This is typically either due to the text on a page being too short, too long, or our web crawler not picking up any text at all.'),
        test_name: translateService.getString('Unknown'),
    },
    lix: {
        'very easy': translateService.getString('The lowest level of readability. Example: children\'s books'),
        easy: translateService.getString('Easy to read pages understood by most. Example: fiction literature for adults'),
        medium: translateService.getString('Medium complexity of text. Example: newspapers and magazines'),
        hard: translateService.getString('Hard to read text. Example: popular science magazines and easier academic publications'),
        'very hard': translateService.getString('Highest level of readability. Example: complex academic and legal texts'),
        na: translateService.getString('Pages we could not determine Readability score'),
        test_name: translateService.getString('Unknown'),
    },
});

export const getGradeSubTitles = (translateService: TranslateService): ReadabilityGradesLixType => (
    {
        lix: {
            'very easy': translateService.getString('Example: children\'s literature'),
            easy: translateService.getString('Example: fiction books'),
            medium: translateService.getString('Example: daily newspapers'),
            hard: translateService.getString('Example: academic publications'),
            'very hard': translateService.getString('Example: legal texts'),
        },
    }
);
