import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.accessibility').component('accHelpCenterBtn', {
        templateUrl: 'app/modules/accessibility/components/help-center-btn/help-center-btn.html',
        controller: AccHelpCenterBtnController,
        controllerAs: 'vm',
        bindings: {
            check: '<',
        },
    });

    AccHelpCenterBtnController.$inject = ['ng2AccessibilityChecksRepoService', 'ng2MonEventsService'];
    /* @ngInject */
    function AccHelpCenterBtnController (ng2AccessibilityChecksRepoService, ng2MonEventsService) {
        var vm = this;
        vm.$onInit = activate;
        vm.openHelpCenter = openHelpCenter;

        function activate () {
            vm.loading = false;
        }

        function openHelpCenter () {
            vm.loading = true;
            ng2AccessibilityChecksRepoService.getHelp(vm.check.id)
                .then(function (data) {
                    if (data.article_url && typeof data.article_url === 'string') {
                        window.open(data.article_url, '_blank');
                    } else if (data.suggestion === '') {
                        // New center
                        openNewCenter(vm.check, data);
                    } else {
                        // Old center
                        openOldCenter(vm.check, data);
                    }
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function openOldCenter (check, data) {
            var params = {
                body: 'accessibilityDialogsHelpCenter',
                data: { check: check, data: data },
                size: 'lg',
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function openNewCenter (check, data) {
            var params = {
                body: 'accessibilityDialogsNewHelpCenter',
                data: { check: check, data: data },
                size: 'lg',
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }
    }
})();
