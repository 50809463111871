import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('modules.data_privacy.pages').component('privacyChecklist', {
        templateUrl: 'app/modules/data-privacy/pages/checklist/checklist.html',
        controller: DataPrivacyChecklistController,
        controllerAs: 'vm',
    });

    DataPrivacyChecklistController.$inject = [
        'ng2DataPrivacyRulesetRepo',
        '$controller',
        'gettextCatalog',
        'REGULATIONS',
        'REGIONS',
        'INFOTYPE_GROUPS',
        'activeFeatures',
        'ng2DataPrivacyService',
        'ng2MonEventsService',
    ];
    /* @ngInject */
    function DataPrivacyChecklistController (
        ng2DataPrivacyRulesetRepo,
        $controller,
        gettextCatalog,
        REGULATIONS,
        REGIONS,
        INFOTYPE_GROUPS,
        activeFeatures,
        ng2DataPrivacyService,
        ng2MonEventsService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.openHelpCenter = openHelpCenter;
        vm.getPage = getPage;
        vm.formatText = ng2DataPrivacyService.formatText;
        vm.getPagesWithActiveViolationCount = ng2DataPrivacyService.getPagesWithActiveViolationCount;
        vm.getTotalPageCount = ng2DataPrivacyService.getTotalPageCount;
        vm.goToPages = goToPages;
        vm.onFiltersChanges = onFiltersChanges;

        vm.exports = [
            {
                name: gettextCatalog.getString('Check List Export'),
                data: {
                    category: 'custom_ruleset',
                },
            },
        ];

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));

            vm.infoTypes = [];
            vm.tabs = [
                {
                    name: gettextCatalog.getString('High'),
                    value: 'high',
                },
                {
                    name: gettextCatalog.getString('Medium'),
                    value: 'medium',
                },
                {
                    name: gettextCatalog.getString('Low'),
                    value: 'low',
                },
                {
                    name: gettextCatalog.getString('Passed'),
                    value: 'passed',
                },
            ];
            vm.activeTab = vm.tabs[0].value;
            vm.currentSort = { by: 'identifier', direction: 'asc' };
            vm.filters = { 'Data Regulations': [], 'Issue type': [], Country: [] };
            vm.availableFilters = {
                'Data Regulations': REGULATIONS,
                'Issue type': INFOTYPE_GROUPS,
                Country: REGIONS,
            };
            vm.showFilters = activeFeatures.table_filters;
            vm.isFiltersOpen = false;

            getPage();
        }

        function getPage () {
            var params = {};

            if (vm.search) {
                params.search = vm.search;
            }
            params.page = vm.page;
            params.page_size = vm.pageSize;
            params.sort_dir = vm.currentSort.direction;
            params.sort_by = vm.currentSort.by;
            params['regulations[]'] = vm.filters['Data Regulations'].map(function (regulation) {
                return regulation.value;
            });
            params['regions[]'] = vm.filters.Country.map(function (region) {
                return region.value;
            });
            params['group[]'] = vm.filters['Issue type'].map(function (type) {
                return type.value;
            });

            if (vm.activeTab === 'passed') {
                params.compliant = true;
            } else {
                params.compliant = false;
                params.priority = vm.activeTab;
            }

            vm.progress = ng2DataPrivacyRulesetRepo.getAll(params).then(function (infoTypes) {
                vm.infoTypes = infoTypes;
            }, angular.noop);
        }

        function openHelpCenter (infoType) {
            var params = {
                size: 'lg',
                body: 'dataPrivacyDialogsHelpCenter',
                data: { infoType: infoType },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function goToPages (infoType) {
            var params = {
                size: 'lg',
                body: 'privacyViolationPages',
                data: { infoType: infoType, totalPagesCount: vm.pageCount },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function onFiltersChanges (changes) {
            vm.filters = changes;
            getPage();
        }
    }
})();
